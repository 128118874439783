<template>
  <div class="container">
    <div class="main">
      <el-row type="flex" justify="space-between" align="middle">
        <el-col :span="1.5">
          <span style="color: #616266; font-weight: bold">个人消息</span>
        </el-col>
      </el-row>

      <div class="msg-list" v-if="msglist.length > 0">
        <div
          class="list-item"
          v-for="(item, idx) in msglist"
		  :class="{ 'un-read': item.isRead == 0 }"
          :key="idx"
        >
          <p @click="seeDetail(item, idx)">
            <span>{{ item.content }}</span>
          </p>
          <p>{{ item.createTime }}</p>
        </div>
      </div>

      <el-empty description="暂无消息" v-else></el-empty>

      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
	  
	  <el-dialog
	  	title="消息详情"
	  	:visible.sync="dialog.detail"
	  	center
	  	width="600px">
	  	<el-form :model="curMsg">
	  		<el-form-item prop="tittle" label="标题：">
	  			<span>{{ curMsg.tittle }}</span>
	  		</el-form-item>
			<el-form-item prop="createTime" label="发布时间：">
				<span>{{ curMsg.createTime }}</span>
			</el-form-item>
	  		<el-form-item prop="content" label="内容：">
	  			<span>{{ curMsg.content }}</span>
	  		</el-form-item>
	  	</el-form>
	  	<div slot="footer">
	  		<el-button @click="closeDialog('detail')">关闭</el-button>
	  	</div>
	  </el-dialog>
    </div>
  </div>
</template>

<script>
import { 
	getPersonal,
	readPersonal
} from "@/api/public/message"
import { msgNum } from '@/mixin/msgNum'
export default {
	mixins: [ msgNum ],
  data() {
    return {
      msglist: [],
      queryParams: {
        pageSize: 15,
      },
      total: 0,
	  dialog: {
		  detail: false
	  },
	  curMsg: {}
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
        getPersonal(this.queryParams).then((res) => {
        this.msglist = res.data.list;
        this.total = res.data.total;
      })
    },
	// 打开
	openDialog(nm) {
		this.dialog[nm] = true
	},
	// 关闭
	closeDialog(nm) {
		this.dialog[nm] = false
	},
	// 详情
	seeDetail(item, idx) {
		if (item.isRead == 0) {
			readPersonal({ id: item.id }).then(res => {
				this.$store.dispatch('user/getAllUnreadMsgNum').then(r => {
					this.msglist[idx].isRead = 1
					this.$emit('minus')
					this._msgTotalNumMinus1()
					this.curMsg = item
					this.openDialog('detail')
				})
			})
		} else if (item.isRead == 1) {
			this.curMsg = item
			this.openDialog('detail')
		}
	}
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/studentResult.scss";
.container {
  .main {
    margin-top: 0;
  }
  .msg-list {
    width: 100%;
    margin-top: 15px;
    .list-item {
      height: 50px;
      border: 1px solid #eceef6;
      border-bottom: none;
      padding: 0 15px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
	  color: #91939a;
      &:last-child {
        border-bottom: 1px solid #eceef6;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        .triangle {
          display: inline-block;
          border: 6px solid;
          border-color: transparent transparent transparent #c9c9c9;
        }
      }
    }
	.un-read {
		color: #409EFF !important;
	}
  }
}
</style>