<template>
  <div class="container" ref="msgIndex">
    <div class="content-box">
		<el-tabs type="border-card" v-model="curTab" @tab-click="switchTab">
		  <el-tab-pane name="/message/policymsg">
		    <div slot="label">
				<span>政策公告</span>
				<el-badge v-if="policyNum > 0" :value="policyNum"></el-badge>
			</div>
		    <Policymsg ref="policymsg" @minus="processPolicyNum"></Policymsg>
		  </el-tab-pane>
		  <el-tab-pane label="消息中心" name="/message/sysmsg">
			<div slot="label">
				<span>系统消息</span>
				<el-badge v-if="sysNum > 0" :value="sysNum"></el-badge>
			</div>
			<Sysmsg ref="sysmsg" @minus="processSysNum"></Sysmsg>
		  </el-tab-pane>
		  <el-tab-pane label="角色管理" name="/message/permsg">
			<div slot="label">
				<span>个人消息</span>
				<el-badge v-if="perNum > 0" :value="perNum"></el-badge>
			</div>
			<Permsg ref="permsg" @minus="processPersonNum"></Permsg>
		  </el-tab-pane>
		</el-tabs>
    </div>
  </div>
</template>

<script>
import { msgNum } from '@/mixin/msgNum'
import Permsg from './permsg/index'
import Policymsg from './policymsg/index'
import Sysmsg from './sysmsg/index'
export default {
	components: {
		Permsg,
		Policymsg,
		Sysmsg
	},
	mixins: [ msgNum ],
  data() {
    return {
		curTab: '/message/policymsg',
		sysNum: this._getMsgNum('systemMessageNum'),
		policyNum: this._getMsgNum('policyMessageNum'),
		perNum: this._getMsgNum('personMessageNum')
    }
  },
  created() {
    this.getPath()
	this.curTab = this.$route.fullPath
  },
  watch: {
    $route: "getPath",
  },
  methods: {
	// 政策消息数量-1
	processPolicyNum() {
		this.policyNum--
		this.policyNum = this._getMsgNum('policyMessageNum')
	},
	// 个人消息数量-1
	processPersonNum() {
		this.perNum--
		this.perNum = this._getMsgNum('personMessageNum')
	},
	// 系统消息数量-1
	processSysNum() {
		this.sysNum--
		this.sysNum = this._getMsgNum('systemMessageNum')
	},
	switchTab(tab) {
		let pageMap = ['/message/policymsg', '/message/sysmsg', '/message/permsg']
		this.$router.push(pageMap[tab.index])
	},
    getPath() {
      if (this.$route.path.indexOf("/policydetail") != -1) {
        this.routePath = "/message/policymsg";
      }else {
        this.routePath = this.$route.path;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  .content-box {
    background: rgba(206, 223, 236, 0.45);
    padding: 18px 18px 18px 0;
    border-radius: 0 0 6px 6px;
    .el-menu {
      border: none;
      .el-menu-item {
		display: flex;
		justify-content: space-between;
		align-items: center;
        font-weight: bold;
        border-left: 4px solid transparent;
        i {
          font-size: 22px;
          font-weight: normal;
          color: #343434;
        }
        &.is-active {
          border-left: 4px solid #198af7 !important;
          background-color: #c3dcef !important;
          i {
            color: #0359ac;
          }
        }
      }
    }
  }
  .no-more {
    height: 50px;
    text-align: center;
    color: rgba(25, 138, 247);
    line-height: 50px;
    background: #cedfec;
    opacity: 0.46;
    border-radius: 6px;
    margin-top: 14px;
  }
}
</style>